
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import Chart from "chart.js";
import { HorizontalBar, mixins } from "vue-chartjs";
import chartjsPluginDatalabels from "chartjs-plugin-datalabels";

@Component({})
export default class HorizontalBarChartComponent extends Mixins(
  HorizontalBar,
  mixins.reactiveProp
) {
  @Prop({ default: false })
  public chartOptions!: Chart.ChartOptions;

  @Watch("chartData")
  onChartDataChanged() {
    this.load();
  }

  mounted() {
    this.addPlugin(chartjsPluginDatalabels);
    this.renderChart(this.chartData, this.chartOptions);
  }

  load() {
    this.addPlugin(chartjsPluginDatalabels);

    this.renderChart(this.chartData, this.chartOptions);
  }
}
