
import { Component, Vue, Prop } from "vue-property-decorator";
import { ICustomerCalc } from "@/types/booster";

@Component({})
export default class extends Vue {
  @Prop()
  public customerList!: ICustomerCalc[];

  //
  // variables
  //
  public headers = [
    { text: "顧客名", value: "customer_name", sortable: false },
    { text: "税抜売上", value: "selling_subtotal_sum", sortable: false },
    { text: "利益額", value: "gross_profit", sortable: false }
  ];

  public calcs_array = {
    selling_amount_total: 0,
    selling_subtotal_total: 0,
    buying_amount_total: 0,
    gross_profit_total: 0
  };

  //
  // methods
  //
  public created() {
    this.customerList.forEach(customer => {
      this.calcs_array["selling_subtotal_total"] +=
        customer.selling_subtotal_sum;
      this.calcs_array["buying_amount_total"] += customer.buying_amount_sum;
      this.calcs_array["gross_profit_total"] += customer.gross_profit;
    });
  }

  get setValue() {
    return this.customerList.map(item => ({
      customer_name: item.customer_name,
      selling_subtotal_sum: Number(item.selling_subtotal_sum).toLocaleString(),
      gross_profit: Number(item.gross_profit).toLocaleString()
    }));
  }
}
