
import { Component, Vue, Prop } from "vue-property-decorator";
import { ISupplierCalc } from "@/types/booster";

@Component({})
export default class extends Vue {
  @Prop()
  public supplierList!: ISupplierCalc[];

  //
  // variables
  //
  public headers = [
    { text: "サプライヤー名", value: "supplier_name", sortable: false },
    { text: "数量", value: "quantity_sum", sortable: false },
    { text: "税抜売上", value: "selling_amount_sum", sortable: false },
    { text: "利益合計", value: "gross_profit", sortable: false }
  ];

  public calcs_array = {
    quantity_total: 0,
    selling_amount_total: 0,
    gross_profit_total: 0
  };

  //
  // methods
  //
  public created() {
    this.supplierList.forEach(supplier => {
      this.calcs_array["quantity_total"] += supplier.quantity_sum;
      this.calcs_array["selling_amount_total"] += supplier.selling_amount_sum;
      this.calcs_array["gross_profit_total"] += supplier.gross_profit;
    });
  }

  get setValue() {
    return this.supplierList.map(item => ({
      supplier_name: item.supplier_name,
      quantity_sum: Number(item.quantity_sum).toLocaleString(),
      selling_amount_sum: Number(item.selling_amount_sum).toLocaleString(),
      gross_profit: Number(item.gross_profit).toLocaleString()
    }));
  }
}
