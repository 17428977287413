
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import Chart from "chart.js";
import { Doughnut, mixins } from "vue-chartjs";

@Component({})
export default class DoughnutChartComponent extends Mixins(
  Doughnut,
  mixins.reactiveProp
) {
  @Prop({ default: false })
  public chartOptions!: Chart.ChartOptions;

  @Watch("chartData")
  onChartDataChanged() {
    this.load();
  }

  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  }

  load() {
    this.renderChart(this.chartData, this.chartOptions);
  }
}
